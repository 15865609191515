import { FC } from 'react';

import styled from '@emotion/styled';
import { yupResolver } from '@hookform/resolvers/yup';
import Image from 'next/image';
import CloseGrayButton from 'public/assets/Gray.webp';
import { FormContainer, useForm } from 'react-hook-form-mui';

import { useApiUserspasswordChangePatch } from '@fllite-fe/api';
import { useAuthentication, useFlashMessage } from '@fllite-fe/shared';
import { Button } from '@fllite-fe/shared/src/components/Button';
import PasswordElement from '@fllite-fe/shared/src/mui-form-fields/PasswordElement';

import { schema } from './validationSchema';

const ModalBox = styled('div')`
	background: #ffffff;
	border-radius: 8px;
	padding: 0 20px;
`;

const ModalTitleBox = styled('div')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 20px;
`;

const ModalTitle = styled('div')`
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;
	color: #353535;
`;

const ModalCloseButton = styled('div')`
	cursor: pointer;
`;

const TitleInputContainer = styled('div')`
	margin: 1rem 0 0 1rem;
	font-weight: 500;
	font-size: 12px;
	line-height: 160%;
`;

const InputContainer = styled('div')`
	margin-bottom: 20px;
`;

const Row = styled('div')`
	position: relative;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 2rem;
		display: flex;
		align-items: flex-start;
	}
`;

const Col = styled('div')`
	margin-top: 25px;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 0;
		width: 100%;
		padding: 0 15px;
	}
`;

const BottomButtonContainer = styled('div')`
	display: flex;
	margin: 40px 0;
`;

const CancelButton = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9px 30px;
	width: 118px;
	height: 44px;
	background: #ffffff;
	border: 1px solid #0056be;
	box-sizing: border-box;
	border-radius: 8px;
	color: #0056be;
	font-weight: 500;
	font-size: 16px;
	line-height: 160%;
	text-align: center;
	color: #0056be;
	margin: 0px 30px;
`;

const ConfirmNewPriceButton = styled(Button)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9px 30px;
	width: 224px;
	height: 44px;
	background: linear-gradient(99.78deg, #e95600 0%, #ff9200 100%);
	border-radius: 8px;
	color: #ffffff;
	font-weight: 500;
	font-size: 16px;
	line-height: 160%;
	text-align: center;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin: 0px 30px;
`;

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => {
	let err;

	switch (true) {
		case /invalid_grant/.test(errorMessage):
			err = 'Wrong original password';
			break;
		case /match/.test(errorMessage):
			err = 'The new password and its confirmation do not match';
			break;
		case /old/.test(errorMessage):
			err = 'The new password cannot be the same as the old one';
			break;
		default:
			err = 'There was an error while changing the password.';
			break;
	}
	return err;
};

interface Props {
	closeModal: () => void;
}

const PasswordChangeModal: FC<Props> = ({ closeModal }) => {
	const { FlashMessageTypes, pushFlashMessage } = useFlashMessage();

	const { user } = useAuthentication();
	const username = user?.email ?? '';

	const { signIn } = useAuthentication();
	const { mutate: confirmPassword } = useApiUserspasswordChangePatch();

	const handleFormSubmit = async (values) => {
		try {
			if (values.newPassword !== values.confirmNewPassword) {
				throw Error('The new password and its confirmation do not match');
			}

			if (values.currentPassword === values.confirmNewPassword) {
				throw Error('The new password cannot be the same as the old one');
			}

			await signIn({ username, password: values.currentPassword });
			await confirmPassword({ data: { plainPassword: values.confirmNewPassword } });

			pushFlashMessage({
				type: FlashMessageTypes.Success,
				text: 'You have successfully changed your password',
			});

			setTimeout(() => {
				closeModal();
			}, 2000);
		} catch (error) {
			pushFlashMessage({
				type: FlashMessageTypes.Error,
				text: <ErrorMessage errorMessage={error.message} />,
			});
		}
	};

	const formContext = useForm({
		resolver: yupResolver(schema),
	});

	return (
		<FormContainer onSuccess={handleFormSubmit} formContext={formContext}>
			<ModalBox>
				<ModalTitleBox>
					<ModalTitle>Change password</ModalTitle>
					<ModalCloseButton onClick={() => closeModal()}>
						<Image src={CloseGrayButton} alt="close" />
					</ModalCloseButton>
				</ModalTitleBox>
				<div>
					<Row>
						<Col>
							<TitleInputContainer>Current password</TitleInputContainer>
							<InputContainer>
								<PasswordElement
									name="currentPassword"
									placeholder="Type current password"
									variant="standard"
									iconColor="primary"
									isStartAdornment={false}
									fullWidth
								/>
							</InputContainer>
						</Col>
					</Row>
					<Row>
						<Col>
							<TitleInputContainer>New password</TitleInputContainer>
							<InputContainer>
								<PasswordElement
									name="newPassword"
									placeholder="Type a new password"
									variant="standard"
									iconColor="primary"
									isStartAdornment={false}
									fullWidth
								/>
							</InputContainer>
						</Col>
					</Row>
					<Row>
						<Col>
							<TitleInputContainer>Confirm new password</TitleInputContainer>
							<InputContainer>
								<PasswordElement
									name="confirmNewPassword"
									placeholder="Type again the new password"
									variant="standard"
									iconColor="primary"
									isStartAdornment={false}
									fullWidth
								/>
							</InputContainer>
						</Col>
					</Row>
					<BottomButtonContainer>
						<CancelButton onClick={() => closeModal()}>Cancel</CancelButton>
						<ConfirmNewPriceButton type="submit">Confirm new password</ConfirmNewPriceButton>
					</BottomButtonContainer>
				</div>
			</ModalBox>
		</FormContainer>
	);
};

export default PasswordChangeModal;
