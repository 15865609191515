import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { ListGroup, ListGroupItem } from 'reactstrap';

import { useFlightOperator } from '@fllite-fe/shared';

import { links } from './links';

const NavContainer = styled('div')`
	padding: 0;
	margin: auto;
	max-height: 46vh;
	height: auto;
	overflow-x: hidden;
`;

const LogoContainer = styled('div')`
	width: 120px;
	height: 100px;
	margin: 15px 0 0 20px;
`;

const DefaultLogoImage = styled('img')`
	padding: 12px 0 0;
`;

const LogoImage = styled('img')`
	max-height: 100%;
	max-width: 100%;
`;

const MenuGroup = styled('div')`
	margin-top: 27px;
`;

const MenuText = styled('div')`
	margin: 23px 0;
	font-size: 14px;
	line-height: 165%;
	color: #353535;
	weight: 400;
`;

const MenuLabel = styled('div')<{ active: boolean }>`
	display: flex;
	background: ${({ active, theme }) => (active ? theme.colors.grayLighter : null)};
	border-left: ${({ active, theme }) => (active ? `3px solid ${theme.colors.primary}` : null)};
	padding-left: 20px;
`;

const Icon = styled('img')`
	padding-right: 15px;
`;

export const DesktopMenu = () => {
	const { pathname } = useRouter();
	const { charterOperator: operator } = useFlightOperator();

	return (
		<NavContainer>
			<LogoContainer>
				{operator && operator.companyLogoUrl ? (
					<LogoImage src={operator.companyLogoUrl} alt="Company logo" />
				) : (
					<DefaultLogoImage src="/assets/logo_fllite_ops.svg" alt="Company logo" />
				)}
			</LogoContainer>
			<MenuGroup>
				<ListGroup flush tag="div">
					{links.map((item) => {
						return (
							<ListGroupItem key={`l${item.name}`} tag="a" href={item.href}>
								<MenuLabel active={item.href === pathname}>
									<Icon src={item.icon} />
									<MenuText>{item.label}</MenuText>
								</MenuLabel>
							</ListGroupItem>
						);
					})}
				</ListGroup>
			</MenuGroup>
		</NavContainer>
	);
};
