import styled from '@emotion/styled';

export const Line = styled('div')`
	width: 24px;
	height: 2px;
	border-radius: 12px;
	margin: 4px 0;
	transition: 0.4s;
`;

interface NavigationProps {
	isOpen: boolean;
}

export const Burger = styled('div')<NavigationProps>`
	display: inline-block;
	cursor: pointer;
	position: ${({ isOpen }) => (isOpen ? 'fixed' : 'relative')};
	right: ${({ isOpen }) => (isOpen ? '20px' : 'auto')};
	top: ${({ isOpen }) => (isOpen ? '20px' : 'auto')};
	z-index: 30;
	padding: 10px;

	${({ theme }) => theme.breakpoints.up('md')} {
		display: none;
	}

	${Line} {
		background-color: ${({ theme, isOpen }) => (isOpen ? theme.colors.primary : '#fff')};

		&:first-of-type {
			transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg) translate(-3px, 3px)' : 'none')};
		}

		&:nth-child(2) {
			opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
		}

		&:last-child {
			transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg) translate(-6px, -6px)' : 'none')};
		}
	}
`;
