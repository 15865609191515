export const links = [
	{
		name: 'trips',
		as: 'a',
		href: '/',
		label: 'Trips',
		icon: '/assets/Trip.svg',
	},
	{
		name: 'aircraft',
		as: 'a',
		href: '/aircraft',
		label: 'Aircraft',
		icon: '/assets/Plane.svg',
	},
	{
		name: 'crew',
		as: 'a',
		href: '/crew',
		label: 'Crew',
		icon: '/assets/Crew.svg',
	},
];
