import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import Head from 'next/head';
import Link from 'next/link';
import { Col, Container, Row } from 'reactstrap';

import { useFlightOperator } from '@fllite-fe/shared';
import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

import { DesktopMenu } from './Navigation/DesktopMenu';
import { MobileMenu } from './Navigation/MobileMenu';
import NavBar from './Navigation/NavBar';

const MainContainer = styled(Container)`
	background: #ffffff;
	box-shadow: 4px 0px 30px rgba(53, 53, 53, 0.06);
`;

const LeftContainer = styled(Col)`
	height: 100%;
	position: fixed;
	width: 160px;
`;

const LayoutContent = styled(Col)`
	height: 100%;
	min-height: 90vh;
	padding-bottom: 0.75rem;
	position: relative;
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-left: 160px;
	}
`;

const BottomLeftBar = styled('div')`
	z-index: -1;
	position: absolute;
	top: 350px;
	background: #edf0f4;
	transform: matrix(1, 0, 0, -1, 0, 0);
	width: 160px;
	height: 2846px;
`;

const Curve = styled('img')``;

const ChildrenWrapper = styled('div')`
	position: relative;
`;

const HeaderContainer = styled('header')`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: linear-gradient(92.58deg, #002553 0%, #0056be 100%);
	padding: 0 4rem;
`;

const LogoWrapper = styled('a')`
	background: #ffffff;
	border-radius: 10% / 50%;
	min-height: 60px;
	display: flex;
	align-items: center;
	padding: 0 1rem;
`;

const Logo = styled('img')``;

interface MainLayoutProps {
	children: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));
	const { charterOperator: operator } = useFlightOperator();

	return (
		<MainContainer>
			<Head>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
				/>
			</Head>
			<Row>
				{isDesktop ? (
					<LeftContainer>
						<DesktopMenu />
						<Curve src="/assets/curve_navbar.svg" alt="" />
						<BottomLeftBar />
					</LeftContainer>
				) : (
					<HeaderContainer>
						<Link href="/" legacyBehavior>
							<LogoWrapper>
								<Logo src={operator?.companyLogoUrl ?? '/assets/logo_fllite_ops.svg'} alt="Logo" />
							</LogoWrapper>
						</Link>
						<MobileMenu />
					</HeaderContainer>
				)}
				<LayoutContent>
					{isDesktop && <NavBar />}
					<ChildrenWrapper>{children}</ChildrenWrapper>
				</LayoutContent>
			</Row>
		</MainContainer>
	);
};

export default MainLayout;
