import { FC } from 'react';

import styled from '@emotion/styled';

import { Icon } from './Icon';

interface CloseIconProps {
	onClick?: () => void;
	className?: string;
	small?: boolean;
}

const CloseIconWrapper = styled('div')`
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 10;
`;

export const CloseIcon: FC<CloseIconProps> = ({ onClick, className, small }) => (
	<CloseIconWrapper onClick={onClick} className={className}>
		{small ? <Icon type="close-gray-small" /> : <Icon type="close-gray" />}
	</CloseIconWrapper>
);
