import { useState } from 'react';

import styled from '@emotion/styled';
import { Box, Drawer, List, ListItem, ListItemButton } from '@mui/material';
import Link from 'next/link';

import { links } from './links';
import { Burger, Line } from './Mobile.style';
import NavBar from './NavBar';

const MenuButton = styled(ListItemButton)`
	font-size: 16px;
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
`;

const MenuIcon = styled('img')`
	padding-right: 10px;
`;

export const MobileMenu = () => {
	const [isOpen, setIsOpen] = useState(false);

	const handleBurgerClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Burger data-testid="Burger-button" onClick={handleBurgerClick} isOpen={isOpen}>
				<Line />
				<Line />
				<Line />
			</Burger>
			<Drawer
				open={isOpen}
				onClose={() => setIsOpen(false)}
				anchor="right"
				PaperProps={{ sx: { width: '80%' } }}
			>
				<Burger data-testid="Burger-button" onClick={handleBurgerClick} isOpen={isOpen}>
					<Line />
					<Line />
					<Line />
				</Burger>
				<Box sx={{ marginTop: '68px' }}>
					<List>
						{links.map((menuItem) => (
							<ListItem key={menuItem.name}>
								<Link passHref href={menuItem.href} legacyBehavior>
									<MenuButton>
										<MenuIcon src={menuItem.icon} alt={menuItem.name} />
										{menuItem.label}
									</MenuButton>
								</Link>
							</ListItem>
						))}
						<NavBar includeToMobileMenu customHandleClose={handleBurgerClick} />
					</List>
				</Box>
			</Drawer>
		</>
	);
};
