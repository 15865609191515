import { FC, ReactNode, useEffect, useState } from 'react';

import { Box, Breakpoint, Dialog } from '@mui/material';

import { CloseIcon } from './CloseIcon';

export interface DialogTitleProps {
	open?: boolean;
	onClose?: () => void;
	size?: Breakpoint;
	closeIcon?: ReactNode;
	trigger?: ReactNode;
	onUnmount?: () => void;
	closeOnDimmerClick?: boolean;
	borderRadius?: number | string;
	margin?: number | string;
	withCloseIcon?: boolean;
	children?: ReactNode;
}

export const Modal: FC<DialogTitleProps> = ({
	open,
	children,
	onClose,
	size,
	closeIcon,
	trigger,
	onUnmount,
	closeOnDimmerClick,
	borderRadius = 2,
	margin = '32px',
	withCloseIcon,
}) => {
	const [customOpen, setCustomOpen] = useState(false);

	useEffect(() => {
		return () => {
			onUnmount && onUnmount();
		};
	});

	const handleClickOpen = () => {
		if (trigger) {
			setCustomOpen(true);
		}
	};

	const handleClose = (event, reason) => {
		const closeFunction = trigger ? () => setCustomOpen(false) : () => onClose();
		if (closeOnDimmerClick || (reason ?? '') !== 'backdropClick') {
			closeFunction();
		}
	};

	const handleOnClose = () => {
		const closeFunction = trigger ? () => setCustomOpen(false) : () => onClose();
		closeFunction();
	};

	return (
		<>
			{trigger && (
				<Box sx={{ display: 'inline-flex', alignItems: 'center' }} onClick={handleClickOpen}>
					{trigger}
				</Box>
			)}
			<Dialog
				PaperProps={{
					style: { borderRadius, margin },
				}}
				maxWidth={size}
				open={trigger ? customOpen : open}
				onClose={handleClose}
			>
				{(closeIcon || withCloseIcon) && (
					<Box onClick={handleOnClose} sx={{ position: 'absolute', right: '0', top: '0' }}>
						{closeIcon ?? <CloseIcon />}
					</Box>
				)}
				{children}
			</Dialog>
		</>
	);
};
