import { FC, useState } from 'react';

import styled from '@emotion/styled';
import { Menu, MenuItem } from '@mui/material';
import Link from 'next/link';
import { Navbar } from 'reactstrap';

import { useAuthentication } from '@fllite-fe/shared';
import { Modal } from '@fllite-fe/shared/src/components/Modal';
import { Text } from '@fllite-fe/shared/src/components/Text';

import ChangePasswordModal from '../ChangePasswordModal';

const UpperNavbar = styled(Navbar)`
	height: 80px;
	left: 0px;
	top: 0px;
	background: linear-gradient(92.58deg, #002553 0%, #0056be 100%);
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
`;

const IconsContainer = styled('div')`
	display: flex;
	padding-right: 40px;
`;

const Circle = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	background: #002553;
	opacity: 0.75;
	border-radius: 50px;
	margin: 0 20px;
	cursor: pointer;
`;

const Icon = styled('img')``;

interface DropdownMenuTextContainerProps {
	includeToMobileMenu?: boolean;
}
const DropdownMenuTextContainer = styled(Text)<DropdownMenuTextContainerProps>`
	display: flex;
	font-size: 16px;
	line-height: 165%;
	color: ${({ includeToMobileMenu, theme }) =>
		includeToMobileMenu ? theme.colors.primary : '#ffffff'};
	cursor: pointer;
`;

interface DropdownIconProps {
	includeToMobileMenu?: boolean;
}
const DropdownIcon = styled('img')<DropdownIconProps>`
	padding-right: 10px;
	${({ includeToMobileMenu }) => includeToMobileMenu && 'filter: invert(0.45);'}
`;

const Separator = styled('div')`
	width: 175px;
	height: 1px;
	border-bottom: 1.5px solid ${({ theme }) => theme.colors.primary};
	margin: 10px 0;
	width: 100%;
	display: none;
	${({ theme }) => theme.breakpoints.up('md')} {
		display: block;
	}
`;

interface Props {
	includeToMobileMenu?: boolean;
	customHandleClose?: () => void;
}

const NavBar: FC<Props> = ({ includeToMobileMenu = false, customHandleClose }) => {
	const { logout } = useAuthentication();
	const [isPasswordModalOpen, setPasswordModalToOpen] = useState(false);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleLogOut = () => {
		logout();
	};

	return (
		<>
			{includeToMobileMenu ? (
				<Links
					handleLogOut={handleLogOut}
					setPasswordModalToOpen={setPasswordModalToOpen}
					handleClose={() => {
						customHandleClose?.();
					}}
					includeToMobileMenu
				/>
			) : (
				<UpperNavbar fixed="top">
					<IconsContainer>
						<Circle
							onClick={handleClick}
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							style={{ border: 'none' }}
						>
							<Icon src="/assets/Settings.svg" alt="settings icon" />
						</Circle>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								'aria-labelledby': 'basic-button',
								sx: { backgroundColor: 'rgb(0, 37, 83)', padding: '12px 6px' },
							}}
						>
							<Links
								handleLogOut={handleLogOut}
								setPasswordModalToOpen={setPasswordModalToOpen}
								handleClose={handleClose}
							/>
						</Menu>
					</IconsContainer>
				</UpperNavbar>
			)}

			<Modal
				size="sm"
				open={isPasswordModalOpen}
				onClose={() => setPasswordModalToOpen(false)}
				closeOnDimmerClick={false}
			>
				<ChangePasswordModal closeModal={() => setPasswordModalToOpen(false)} />
			</Modal>
		</>
	);
};

interface LinksProps {
	handleLogOut: () => void;
	setPasswordModalToOpen: (value: boolean) => void;
	handleClose: () => void;
	includeToMobileMenu?: boolean;
}

const Links: FC<LinksProps> = ({
	handleLogOut,
	setPasswordModalToOpen,
	handleClose,
	includeToMobileMenu,
}) => {
	return (
		<>
			<Link href="/edit-general-information" passHref legacyBehavior>
				<MenuItem>
					<DropdownMenuTextContainer includeToMobileMenu={includeToMobileMenu}>
						<DropdownIcon src="/assets/Edit_profile.svg" alt="edit profile" includeToMobileMenu />
						Edit General Information
					</DropdownMenuTextContainer>
				</MenuItem>
			</Link>
			<MenuItem
				onClick={() => {
					setPasswordModalToOpen(true);
					!includeToMobileMenu && handleClose();
				}}
			>
				<DropdownMenuTextContainer includeToMobileMenu={includeToMobileMenu}>
					<DropdownIcon src="/assets/Password.svg" alt="change password" includeToMobileMenu />
					Change Password
				</DropdownMenuTextContainer>
			</MenuItem>
			<Separator />
			<MenuItem onClick={() => handleLogOut()}>
				<DropdownMenuTextContainer includeToMobileMenu={includeToMobileMenu}>
					<DropdownIcon src="/assets/Logout.svg" alt="edit profile" includeToMobileMenu />
					Logout
				</DropdownMenuTextContainer>
			</MenuItem>
		</>
	);
};

export default NavBar;
